import StatisticCard from 'components/StatisticCard';
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';

const PoolStatistics: React.FC = () => {
   const { t } = useTranslation(nameOf({PoolStatistics}), { useSuspense: false});

       const {
           pools,
           loadingPools
       } = useAppSelector(state => state.middlewareChrono);
       

   useEffect(() => {
   }, [])


   const totalPoolOptimized = pools.filter(p => p.optimizationCompletion === 'COMPLET').length;
   const totalPoolPartial = pools.filter(p => p.optimizationCompletion === 'PARTIAL').length;
   const totalPoolIgnored = pools.filter(p => p.optimizationCompletion === 'IGNORE').length;
   const totalPoolWaitingOptimization = pools.filter(p => p.optimizationCompletion === 'WAITING_OPTIMIZATION').length;

   return (<Row className='justify-content-evenly'>
    <Col className='mb-3'><StatisticCard title={t("Nombre de pools")} color='blue' value={`${pools.length}`} icon='boxes-stacked' loading={loadingPools}/></Col>
    <Col className='mb-3'><StatisticCard title={t("Totalement optimisés")} color='green' value={`${totalPoolOptimized}`} icon='check' loading={loadingPools}/></Col>
    <Col className='mb-3'><StatisticCard title={t("En attente")} color='yellow' value={`${totalPoolWaitingOptimization}`} icon='hourglass' loading={loadingPools}/></Col>
    <Col className='mb-3'><StatisticCard title={t("Partiellement optimisés")} color='orange' value={`${totalPoolPartial}`} icon='warning' loading={loadingPools}/></Col>
    <Col className='mb-3'><StatisticCard title={t("Pools ignorés")} color='red' value={`${totalPoolIgnored}`} icon='ban' loading={loadingPools}/></Col>
    </Row>)
}
export default PoolStatistics