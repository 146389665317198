import PageHeader from 'components/PageHeader';
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/store';
import nameOf from 'utility/nameOf';
import PoolList from './Components/PoolList';


const DashboardChrono: React.FC = () => {
   const { t } = useTranslation(nameOf({DashboardChrono}), { useSuspense: false});
   const dispatch = useAppDispatch();

   useEffect(() => {
    
   }, [])

   return (
       <div>
            <PageHeader title={t("Dashboard temps réel")}/>
            <Row>
                <Col><PoolList/></Col>
            </Row>
       </div>
   )
}
export default DashboardChrono