import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { TableColumn } from 'react-data-table-component';
import { OptimizationCompletionEnum, PoolDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import dayjs from 'dayjs';
import { Badge, Form, Tooltip } from 'react-bootstrap';
import PoolStatistics from './PoolStatistics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Select from 'react-select';
import ButtonFno from 'components/inputs/ButtonFno';
import { loadPoolsThunk } from 'store/reducers/Chronodrive/thunks/loadPoolsThunk';

const PoolList: React.FC = () => {
    const { t } = useTranslation(nameOf({PoolList}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const {
        middlewareSelected,
    } = useAppSelector(state => state.middlewareApis);

    const {
        currentMap,
    } = useAppSelector(state => state.map);

    const defaultSectorCodeOption = { value: '', label: t('Tous les secteurs') };
    const [selectedSectorCode, setSelectedSectorCode] = useState<{value:string, label: string}>(defaultSectorCodeOption);

    const {
        pools,
        sectorCodes,
        loadingPools
    } = useAppSelector(state => state.middlewareChrono);

   useEffect(() => {
   }, [])

const getOptimizationCompletionBadge = (status: OptimizationCompletionEnum) => {
    switch (status) {
        case 'IGNORE':
            return <Badge bg="danger"><FontAwesomeIcon icon={['fas', 'ban']}/> {status}</Badge>;
        case 'PARTIAL':
            return <Badge bg="warning"><FontAwesomeIcon icon={['fas', 'warning']}/> {status}</Badge>;
        case 'COMPLET':
            return <Badge bg="success"><FontAwesomeIcon icon={['fas', 'check']}/> {status}</Badge>;
        case 'WAITING_OPTIMIZATION':
            return <Badge bg="primary"><FontAwesomeIcon icon={['fas', 'hourglass']}/> {status}</Badge>;
        default:
            return status;
    }
};

const refreshSearch =() => {
    if(currentMap && middlewareSelected)
    {
        dispatch(loadPoolsThunk(middlewareSelected?.baseUrl, currentMap.reference ?? currentMap.id, 1,1000));
    }
    
}

const columns: TableColumn<PoolDetails>[] = [
    {
        id: 'orderId',
        name: t('Order ID'),
        selector: row => row.orderId,
        sortable: true,
    },
    {
        id: 'poolId',
        name: t('Pool ID'),
        selector: row => row.poolId,
        sortable: true,
    },
    {
        id: 'sectorCode',
        name: t('Sector Code'),
        selector: row => row.sectorCode,
        sortable: true,
    },
    {
        id: 'pickings',
        name: t('Produits'),
        selector: row => row.pickings.length,
        cell: (row) => <>{row.pickings.length}</>,
        sortable: true,
    },
    {
        id: 'status',
        name: t('Status'),
        selector: row => row.status,
        sortable: true,
    },
    {
        id: 'distributionStatus',
        name: t('Distribution Status'),
        selector: row => row.distributionStatus,
        sortable: true,
    },
    {
        id: 'optimizationCompletion',
        name: t('Optimization Completion'),
        selector: row => row.optimizationCompletion,
        cell: (row) => getOptimizationCompletionBadge(row.optimizationCompletion),
        sortable: true,
    },
    {
        id: 'departureTime',
        name: t('Departure Time'),
        selector: row => dayjs(row.departureTime).format('DD/MM/YYYY HH:mm'),
        sortable: true,
        sortFunction: (a, b) => dayjs(a.departureTime).unix() - dayjs(b.departureTime).unix()
    },
    {
        id: 'readyDateTime',
        name: t('Ready Date Time'),
        selector: row => dayjs(row.readyDateTime).format('DD/MM/YYYY HH:mm'),
        sortable: true,
        sortFunction: (a, b) => dayjs(a.readyDateTime).unix() - dayjs(b.readyDateTime).unix(),
    },
];


    const sectorCodeOptions = _.map(sectorCodes, (sectorCode) => ({ value: sectorCode, label: sectorCode }));
    sectorCodeOptions.unshift(defaultSectorCodeOption);

    let poolsFiltered = pools;
    if(selectedSectorCode.value !== '') {
        poolsFiltered = pools.filter(pool => pool.sectorCode === selectedSectorCode.value);
    }


    return (
        <div>
            <h3>{t("Pools en cours de traitement")}</h3>

                <PoolStatistics />
                <TableWithCrudComponent 
                    entities={poolsFiltered} 
                    columns={columns}
                    loadingList={loadingPools}
                    loadingForm={false}
                    addDefaultActionsColumn={false}
                    hideIdColumn={true}
                    defaultSortFieldId='readyDateTime'
                    defaultSortFieldDirection='asc'
                    translations={{
                        tableTitle: `${t("Liste des pools")} (${poolsFiltered.length})`,
                        noEntityText: t("Aucun pool trouvé"),
                        deleteText: (entity) => (""),
                        deleteTitle: (entity) => (""),
                        updateText: (entity) => (""),
                    }}
                    fieldSearchable={(entity) => [
                        entity.id,
                        entity.siteId,
                        entity.sectorCode,
                        entity.orderId,
                        entity.poolId,
                        entity.status,
                        entity.distributionStatus,
                        entity.optimizationCompletion,
                        entity.departureTime,
                        entity.readyDateTime
                    ]}
                    customHeader={<>
                        
                        <Select className='me-2'  options={sectorCodeOptions} value={selectedSectorCode} onChange={code => setSelectedSectorCode(code ?? defaultSectorCodeOption)}/>
                        <ButtonFno className='me-2' color='blue'  onClick={() => refreshSearch()}><FontAwesomeIcon icon={["fas", "refresh"]}/></ButtonFno>
                    </>}
            />
        </div>
    )
}
export default PoolList