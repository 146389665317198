import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { loadPoolsThunk } from 'store/reducers/Chronodrive/thunks/loadPoolsThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';

const LayoutChrono: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { t } = useTranslation(nameOf({LayoutChrono}), { useSuspense: false });
    const dispatch = useAppDispatch();
    const location = useLocation();
    const {
        currentTenant,
    } = useAppSelector(state => state.userProfil);

    const {
        middlewareSelected,
    } = useAppSelector(state => state.middlewareApis);

    const {
        currentMap,
    } = useAppSelector(state => state.map);

    const isChronodriveCustomer = currentTenant && currentTenant.name.toLowerCase() === "chronodrive";

    useEffect(() => {
        // when middleware or selected map changed
        if(middlewareSelected && currentMap)
        {
            // load pools 
            dispatch(loadPoolsThunk(middlewareSelected?.baseUrl, currentMap.reference ?? currentMap.id, 1,1000));
        }
    }, [middlewareSelected, currentMap]);
    
    if(!isChronodriveCustomer){
        return <Navigate to={ROUTES.DASHBOARD} state={{ from: location }} replace />;
    }

    return (
         <div>
              {children}
         </div>
    );
}
export default LayoutChrono;