/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ApiErrorsResponse,
    DistributionEnum,
    PoolDetailsPagedListResult,
    PoolDetailsWithProductsParams,
    PoolOptimizationResult,
    PoolStatusEnum,
    PoolStatusUpdateParams
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Pool<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Pool
     * @name GetPools
     * @summary Récupérer une liste de pools en fonction de critères
     * @request GET:/v1/pools/search
     * @secure
     */
    getPools = (
        query?: {
            /** @format int32 */
            page?: number
            /** @format int32 */
            pageSize?: number
            sortColumn?: string
            sortDirection?: string
            status?: PoolStatusEnum
            /** Distribution status of the pool */
            distributionStatus?: DistributionEnum
        },
        params: RequestParams = {}
    ) =>
        this.request<PoolDetailsPagedListResult, ApiErrorsResponse>({
            path: `/v1/pools/search`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Pool
     * @name CreateOrUpdatePool
     * @summary Créer ou modifier un pool
     * @request PUT:/v1/pools/{chronoId}
     * @secure
     */
    createOrUpdatePool = (chronoId: string, data: PoolDetailsWithProductsParams, params: RequestParams = {}) =>
        this.request<void, ApiErrorsResponse>({
            path: `/v1/pools/${chronoId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        })
    /**
     * No description
     *
     * @tags Pool
     * @name PatchPool
     * @summary Modifier un status
     * @request PATCH:/v1/pools/{chronoId}/status
     * @secure
     */
    patchPool = (chronoId: string, data: PoolStatusUpdateParams, params: RequestParams = {}) =>
        this.request<void, ApiErrorsResponse>({
            path: `/v1/pools/${chronoId}/status`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        })
    /**
     * No description
     *
     * @tags Pool
     * @name GetPoolToPrepare
     * @summary Fourni une liste de commande à préparer
     * @request GET:/v1/pools
     * @secure
     */
    getPoolToPrepare = (
        query?: {
            sectorCode?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<PoolOptimizationResult, ApiErrorsResponse>({
            path: `/v1/pools`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
}
