/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiErrorsResponse {
    errors?: Record<string, string[]>
}

/** Distribution status of the pool */
export enum DistributionEnum {
    WAITING = 'WAITING',
    DISTRIBUTED = 'DISTRIBUTED',
    OVERDUE = 'OVERDUE'
}

export interface HomeStatusResult {
    build: string | null
}

export interface MiddlewareStatusResult {
    realtimeOnline?: boolean
    buildDate?: string | null
}

/** Indiquate the completion of the optimization */
export enum OptimizationCompletionEnum {
    WAITING_OPTIMIZATION = 'WAITING_OPTIMIZATION',
    COMPLET = 'COMPLET',
    PARTIAL = 'PARTIAL',
    IGNORE = 'IGNORE'
}

export interface PoolDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    siteId: string
    /** @minLength 1 */
    sectorCode: string
    /**
     * Id chronodrive sous la forme siteId + ‘-’ + poolId
     * @minLength 1
     */
    chronodriveId: string
    /**
     * Order number
     * @format int32
     */
    orderId: number
    /**
     * Pool ID
     * @format int32
     */
    poolId: number
    status: PoolStatusEnum
    /** Distribution status of the pool */
    distributionStatus: DistributionEnum
    /** Indiquate the completion of the optimization */
    optimizationCompletion: OptimizationCompletionEnum
    /**
     * Requested truck departure time
     * @format date-time
     */
    departureTime: string
    /**
     * time when the pool need to be ready:
     * It depend on the Departure and the number of product(* TpsArt) and
     * the TpsLimite (security time).
     * @format date-time
     */
    readyDateTime: string
    /** Product list */
    pickings: ProductDetails[]
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface PoolDetailsPagedListResult {
    /** @format int32 */
    currentPage?: number
    /** @format int32 */
    pageSize?: number
    sortColumn?: string | null
    sortDirection?: string | null
    items?: PoolDetails[] | null
    /** @format int32 */
    totalCount?: number
    /** @format int32 */
    totalPages?: number
}

export interface PoolDetailsWithProductsParams {
    /**
     * Store code
     * @minLength 1
     */
    siteId: string
    /**
     * Preparation sector code (e.g., PGC 3500, Frais)
     * @minLength 1
     */
    sectorCode: string
    /**
     * Order number
     * @format int32
     */
    orderId: number
    /**
     * Pool ID
     * @format int32
     */
    poolId: number
    status: PoolStatusEnum
    /**
     * Requested truck departure time
     * @minLength 1
     */
    departureTime: string
    pickings: ProductDetails[]
}

export interface PoolOptimizationResult {
    pools?: number[] | null
}

export enum PoolStatusEnum {
    AVAILABLE = 'AVAILABLE',
    UNAVAILABLE = 'UNAVAILABLE'
}

export interface PoolStatusUpdateParams {
    status: PoolStatusEnum
}

export interface ProductDetails {
    /** @minLength 1 */
    storageArea: string
    /** @format int32 */
    shelfId: number
    /** @format int32 */
    columnId: number
    /** @format int32 */
    quantity: number
    /**
     * Location code in the format StorageArea.ShelfId.ColumnId
     * @minLength 1
     */
    locationCode: string
}

export interface SiteSettingDetails {
    /**
     * [CodeSite] Store's code
     * CODE_SITE
     */
    siteId?: string | null
    /**
     * [CodeSecteur] Preparation sector code (e.g., PGC 3500, Frais)
     * CODE_SECTEUR
     */
    sectorCode?: string | null
    /**
     * Average picking time per item (in seconds)
     * NB_ART_CAISSE
     * @format int32
     */
    averagePickingTimeByProduct?: number
    /**
     * minimum number box
     * NB_COLIS_MIN
     * @format int32
     */
    nbMinSupportBatch?: number
    /**
     * mmaximum number box
     * NB_COLIS_MAX
     * @format int32
     */
    nbMaxSupportBatch?: number
    /**
     * Minimum number of boxes
     * NB_CDE_MIN
     * @format int32
     */
    nbMinCommandBatch?: number
    /**
     * Maximum number of orders
     * NB_CDE_MAX
     * @format int32
     */
    nbMaxCommandBatch?: number
    /**
     * Minimum number of items in the multi-pool
     * NB_ART_MIN
     * @format int32
     */
    nbMinProduitBatch?: number
    /**
     * Maximum number of items in the multi-pool
     * NB_ART_MAX
     * @format int32
     */
    nbMaxProduitBatch?: number
    /**
     * Maximum number of items in one pool
     * MAX_ART_CDE
     * @format int32
     */
    nbMaxArticleByPool?: number
    /**
     * Time beyond which a preparation (before the requested delivery date) is no longer eligible for multi-prep
     * TPS_LIMITE to validate
     * @format int32
     */
    securityTimeSeconds?: number
}

export interface SiteSettingUpdate {
    /**
     * [CodeSecteur] Preparation sector code (e.g., PGC 3500, Frais)
     * CODE_SECTEUR
     */
    sectorCode?: string | null
    /**
     * Average picking time per item (in seconds)
     * NB_ART_CAISSE
     * @format int32
     */
    averagePickingTimeByProduct?: number
    /**
     * minimum number box
     * NB_COLIS_MIN
     * @format int32
     */
    nbMinSupportBatch?: number
    /**
     * mmaximum number box
     * NB_COLIS_MAX
     * @format int32
     */
    nbMaxSupportBatch?: number
    /**
     * Minimum number of boxes
     * NB_CDE_MIN
     * @format int32
     */
    nbMinCommandBatch?: number
    /**
     * Maximum number of orders
     * NB_CDE_MAX
     * @format int32
     */
    nbMaxCommandBatch?: number
    /**
     * Minimum number of items in the multi-pool
     * NB_ART_MIN
     * @format int32
     */
    nbMinProduitBatch?: number
    /**
     * Maximum number of items in the multi-pool
     * NB_ART_MAX
     * @format int32
     */
    nbMaxProduitBatch?: number
    /**
     * Maximum number of items in one pool
     * MAX_ART_CDE
     * @format int32
     */
    nbMaxArticleByPool?: number
    /**
     * Time beyond which a preparation (before the requested delivery date) is no longer eligible for multi-prep
     * TPS_LIMITE to validate
     * @format int32
     */
    securityTimeSeconds?: number
}
