import {chronoActions} from '../chronodriveSlice';
import { AppThunk } from "store/store"
import _ from "lodash";
import apiChronodriveDefinition from "backend/apiChronodriveDefinition";
import { DistributionEnum, PoolStatusEnum } from "backend/ApiChronodriveDefinition/data-contracts";


export const loadPoolsThunk = (
    baseUrl: string, 
    mapId: string, 
    page: number,
    pageSize = 30): AppThunk => {
return async (dispatch, getState) => {
  try {
    
    const tenant = getState().userProfil.currentTenant?.tenant
    dispatch(chronoActions.setLoadingPools(true));

    
    const poolsAvailable = await apiChronodriveDefinition(baseUrl, tenant ?? "", mapId)
                              .pool.getPools({
                                page: page,
                                pageSize: pageSize,
                                status: PoolStatusEnum.AVAILABLE,
                                distributionStatus: DistributionEnum.WAITING});

       
    // Get all unique sector codes
    const sectorCodes = _.uniq(poolsAvailable.data.items?.map(pool => pool.sectorCode));                       
    // Set the pools in the store
    dispatch(chronoActions.setPools(poolsAvailable.data.items ?? []));
    // Set the sector codes in the store
    dispatch(chronoActions.setSectorCodes(sectorCodes));

  } catch (error: any) {

    if (!error.status || error.status != 401) {
      console.error("[loadPoolsThunk] Error while loading pools", error);
    }
  } finally {
    // Set loading state back to false after the operation completes
    dispatch(chronoActions.setLoadingPools(false));
  }
};
};