import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PoolDetails } from "backend/ApiChronodriveDefinition/data-contracts";
import { userProfilActions} from "store/reducers/Profil/userProfilSlice";
import { mapActions } from "../Map/mapSlice";

export interface IChronoStore {
  pools: PoolDetails[];
  /**All map sector code existing */
  sectorCodes: string[];
  loadingPools: boolean;
}

const initialState: IChronoStore = {
  loadingPools: false,
  pools: [],
  sectorCodes: [],
};

const chronoSlice = createSlice({
  name: 'chrono',
  initialState,
  reducers: {
    setLoadingPools: (state, action: PayloadAction<boolean>) => {
      state.loadingPools = action.payload;
    },
    setPools: (state, action: PayloadAction<PoolDetails[]>) => {
      state.pools = action.payload;
    },
    setSectorCodes: (state, action: PayloadAction<string[]>) => {
      state.sectorCodes = action.payload;
    }
  },
  
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state) => {
      state.loadingPools = false;
      state.pools = [];
    }),

    // if map change, reset all data
    builder.addCase(mapActions.setCurrentMap, (state) => {
      state.pools = [];
    })
  }
});

export const { actions: chronoActions, reducer: chronoReducer } = chronoSlice;