/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, SiteSettingDetails, SiteSettingUpdate } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Settings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Settings
     * @name CreateOrUpdateSetting
     * @summary Créer ou modifier une configuration pour un site et un secteur
     * @request PUT:/v1/setting/{codeSector}
     * @secure
     */
    createOrUpdateSetting = (codeSector: string, data: SiteSettingUpdate, params: RequestParams = {}) =>
        this.request<SiteSettingDetails, ApiErrorsResponse>({
            path: `/v1/setting/${codeSector}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Settings
     * @name GetSetting
     * @summary Récupère la configuration d'un site et d'un secteur
     * @request GET:/v1/setting/{codeSector}
     * @secure
     */
    getSetting = (codeSector: string, params: RequestParams = {}) =>
        this.request<SiteSettingDetails, ApiErrorsResponse>({
            path: `/v1/setting/${codeSector}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
}
